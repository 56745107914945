import React, { Fragment, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import firebase from "firebase/app";
import "firebase/firestore";
import styled from "styled-components";
import Button from "./elements/Button";
import CustomInput from "./elements/CustomInput";

const SecondStep = ({ items, setItems, id }) => {
  const [itemId, setItemId] = useState(-1);
  const [isUpdate, setIsUpdate] = useState(0);
  const [total, setTotal] = useState(0);
  const db = firebase.firestore();

  const deleteItem = async (i) => {
    const arr = items.filter((item, index) => index !== i);

    await db
      .collection("reservations")
      .doc(id)
      .update({
        prices: arr,
      })
      .then(() => {
        setItems(arr);
        let t = 0;
        arr.map((item) => {
          t += item.total;
        });
        setTotal(t);
      });
  };

  const add = async (data) => {
    const arr = items;
    arr.push({
      name: data.name,
      price: data.price,
      quantity: data.quantity,
      total: data.price * data.quantity,
    });

    await db
      .collection("reservations")
      .doc(id)
      .update({
        prices: arr,
      })
      .then(() => {
        setItems(arr);
        let t = 0;
        arr.map((item) => {
          t += item.total;
        });
        setTotal(t);
      });
  };

  const updateScenario = async (data) => {
    const arr = items;

    arr.map((item, index) => {
      if (index === itemId) {
        item.name = data.name;
        item.price = data.price;
        item.quantity = data.quantity;
        item.total = data.price * data.quantity;
      }
    });

    await db
      .collection("reservations")
      .doc(id)
      .update({
        prices: arr,
      })
      .then(() => {
        setItems(arr);
        setItemId(-1);
        let t = 0;
        arr.map((item) => {
          t += item.total;
        });
        setTotal(t);
      });
  };

  useEffect(() => {
    let t = 0;
    items.map((item) => {
      t += item.total;
    });
    setTotal(t);
  }, []);

  return (
    <Container>
      <Formik
        initialValues={{
          name: "",
          price: 0,
          quantity: 0,
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("required"),
          price: Yup.number()
            .typeError("Doit être un nombre")
            .min(0, "Doit être supérieur a 0"),
          quantity: Yup.number()
            .typeError("Doit être un nombre")
            .min(0, "Doit être supérieur a 0"),
        })}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          if (isUpdate) {
            await updateScenario(data);
          } else {
            await add(data);
          }
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <Form className={itemId === -1 ? "form" : "form edit-form"}>
            <div className="table">
              <div className="grid">
                <h6>Désignation</h6>
                <h6>Prix Unitair</h6>
                <h6>Quantité</h6>
                <h6>Total</h6>
                <h6 />
                {items.map((item, index) => (
                  <Fragment key={index}>
                    <h5>{item.name}</h5>
                    <h5>{item.price} DH</h5>
                    <h5>{item.quantity}</h5>
                    <h5>{item.total} DH</h5>
                    <h5 className="delete">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="#f84b60"
                        onClick={() => deleteItem(index)}
                      >
                        <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="#777"
                        style={{ marginLeft: "1em" }}
                        onClick={() => {
                          setItemId(index);
                          setFieldValue("name", item.name);
                          setFieldValue("price", item.price);
                          setFieldValue("quantity", item.quantity);
                          window.scrollTo({
                            top: document.body.scrollHeight,
                            behavior: "smooth",
                          });
                        }}
                      >
                        <path d="M19.769 9.923l-12.642 12.639-7.127 1.438 1.438-7.128 12.641-12.64 5.69 5.691zm1.414-1.414l2.817-2.82-5.691-5.689-2.816 2.817 5.69 5.692z" />
                      </svg>
                    </h5>
                  </Fragment>
                ))}

                <CustomInput name="name" id="name" type="text" />
                <CustomInput name="price" id="price" type="text" />
                <CustomInput name="quantity" id="quantity" type="text" />
                <div />

                {itemId === -1 ? (
                  <Button
                    handleClick={handleSubmit}
                    title="+"
                    type="submit"
                    margin="1em"
                    padding="8px 10px"
                    color="#3B4F64"
                    radius="120px"
                  />
                ) : (
                  <button
                    className="confirm"
                    type="button"
                    onClick={() => {
                      setIsUpdate(1);
                      handleSubmit();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="#187bcd"
                    >
                      <path d="M0 11.386l1.17-1.206c1.951.522 5.313 1.731 8.33 3.597 3.175-4.177 9.582-9.398 13.456-11.777l1.044 1.073-14 18.927-10-10.614z" />
                    </svg>
                  </button>
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="price-row">
        <div className="price-row-wrp">
          <p className="blue">Prix Hors Taxe </p>
          <p className="total">{total} dhs </p>
        </div>
        <div className="price-row-wrp">
          <p className="blue">TVA</p>
          <p className="total">20% </p>
        </div>
        <div className="price-row-wrp">
          <p className="blue">Total Net à payer TTC </p>
          <p className="total bold">{total + total * 0.2} dhs </p>
        </div>
      </div>
    </Container>
  );
};

export default SecondStep;

const Container = styled.div`
  max-width: 1200px;
  padding: 0 2em;
  .page-container {
    padding: 0 1em 1em 1em;
    border-radius: 10px;
    background: #fff;
    box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
      rgb(237 239 247 / 47%) 0px 0px 0px;
  }
  .form {
    width: 100%;
    margin-top: 2em;
    padding: 2em 0;
    border-radius: 20px;
    background: #fff;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    margin-bottom: 2em;
  }
  h6 {
    color: rgb(128, 128, 128);
    font-weight: 600;
    font-size: 13px;
    text-transform: capitalize;
    color: #3b4f64;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  h5 {
    color: rgb(128, 128, 128);
    font-size: 13px;
    font-weight: 600;
    color: #202862;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
    text-transform: capitalize;
  }
  .table {
    overflow-x: auto;
    max-width: 1200px;
  }
  .grid {
    display: grid;
    grid-template-columns: 2.2fr 1fr 1fr 1fr 40px;
    grid-template-rows: auto;
    border-bottom: 1px solid #dfe0eb;
    max-width: 1200px;
    width: 100%;
  }
  input {
    min-width: 70px;
    margin: 1em 0.5em 1em 0;
    width: calc(100% - 0.5em);
    border-radius: 5px !important;
  }
  label {
    display: none;
  }
  button {
    margin: 1em 0;
    width: 100% !important;
  }
  .delete {
    justify-content: center;
    padding: 0;
    svg {
      cursor: pointer;
    }
  }
  p {
    font-size: 11px;
    color: red;
    font-weight: 600;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    button {
      margin-top: 0;
      margin-bottom: 2.2em;
    }
  }
  .confirm {
    background: transparent !important;
    border-radius: 7px;
    border: 1.5px solid #187bcd;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .edit-form {
    input {
      border: 1.5px solid #187bcd;
    }
  }
  .price-row {
    display: flex;
    width: 100%;
    align-items: flex-end;
    margin-top: 1.5em;
    margin-bottom: 2em;
    flex-direction: column;
  }
  .price-row-wrp {
    display: grid;
    grid-template-columns: 200px 100px;
  }
  .blue {
    font-size: 15px;
    font-weight: 600;
    color: #5276ea;
    margin-right: 2em;
    margin-top: 1em;
  }
  .total {
    font-size: 15px;
    font-weight: 500;
    color: #202862;
    margin-top: 1em;
  }
  .bold {
    font-weight: 700;
  }
  @media only screen and (max-width: 900px) {
    padding: 0;
  }
  @media only screen and (max-width: 576px) {
    .price-row {
      align-items: flex-start;
    }
    .price-row-wrp {
      display: grid;
      grid-template-columns: 150px 70px;
    }
  }
`;
