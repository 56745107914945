import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import Steps from "../components/Steps";
import Layout from "../layouts/DefaultLayout";
import FirstStep from "../components/FirstStep";
import SecondStep from "../components/SecondStep";
import ThirdStep from "../components/ThirdStep";
import CancelPopup from "../components/CancelPopup";
import Popup from "../components/elements/Popup";

const ReservationsDetails = () => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState(null);
  const [items, setItems] = useState([]);
  const [cancel, setCancel] = useState(0);
  const [popupIsActive, setPopupIsActive] = useState(0);
  const location = useLocation();
  const db = firebase.firestore();

  useEffect(() => {
    if (location?.state) {
      setData(location.state);
      if (location.state.prices) {
        setItems(location.state.prices);
      }
      if (location.state.status === "Confirmed") {
        setStep(3);
      }
    }
  }, [location]);

  const submit = async () => {
    await db
      .collection("reservations")
      .doc(data.id)
      .update({
        status: "Confirmed",
      })
      .then(() => {
        setStep(3);
      });
  };

  return (
    <Layout>
      <Container>
        <Steps step={step} />
        {step === 1 ? (
          <FirstStep data={data} />
        ) : step === 2 ? (
          <SecondStep items={items} setItems={setItems} id={data.id} />
        ) : (
          <ThirdStep items={items} id={data.id} paid={data.paid} />
        )}
        {step < 3 ? (
          <div className="navigation">
            <button
              className={step === 1 ? "btn cancel" : "btn"}
              onClick={() => (step === 1 ? setCancel(1) : setStep(step - 1))}
            >
              {step === 1 ? "Annuler la demande" : "Précédent"}
            </button>
            <button
              className="btn confirm"
              onClick={() => {
                step === 1 ? setStep(2) : setPopupIsActive(1);
              }}
            >
              {step === 1 ? "Confirmer la demande" : "Enregistrer et envoyer"}
            </button>
          </div>
        ) : null}
        {cancel ? <CancelPopup setCancel={setCancel} id={data.id} /> : null}
        {popupIsActive ? (
          <Popup setAction={setPopupIsActive} submit={submit} />
        ) : null}
      </Container>
    </Layout>
  );
};

export default ReservationsDetails;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 2em 2em 3em 2em;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  max-width: 1200px;
  margin: auto;
  width: 100%;
  .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    margin-top: 4em;
    padding: 0 2em;
  }
  .btn {
    color: #fff;
    border-radius: 8px;
    background-color: #cdd3da;
    padding: 7px 15px;
    font-weight: 600;
  }
  .cancel {
    background-color: #c30a1d;
  }
  .confirm {
    background-color: #5276ea;
  }
  @media only screen and (max-width: 900px) {
    .navigation {
      padding: 0;
    }
  }
`;
