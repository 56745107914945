import React, { useState, useEffect } from "react";
import styled from "styled-components";
import jwt from "jsonwebtoken";
import { useHistory, useLocation } from "react-router-dom";
import Menu from "../components/Menu";
import Header from "../components/Header";

const DefaultLayout = ({ children }) => {
  const [menuIsActive, setMenuIsActive] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(0);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const admin = localStorage.getItem("auth");

    jwt.verify(admin, "d6d82b79-5226-454c-a36d-17bc13bcd6f2", (err) => {
      if (err) {
        history.push("/login");
      } else {
        setIsLoggedIn(1);
      }
    });
  }, []);

  if (!isLoggedIn) {
    return null;
  }

  return (
    <Container url={location.pathname}>
      <div className="menu-wrp">
        <Menu menuIsActive={menuIsActive} setMenuIsActive={setMenuIsActive} />
      </div>
      <div className="body">
        <Header
          setMenuIsActive={setMenuIsActive}
          menuIsActive={menuIsActive}
          path={location.pathname}
        />
        <div className="content">
          {React.cloneElement(children, { url: location.pathname })}
        </div>
      </div>
    </Container>
  );
};

export default DefaultLayout;

const Container = styled.div`
  display: grid;
  grid-template-columns: 240px auto;
  background: #f7f8fb;
  .body {
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
  }
  .content {
    padding: 5em 1em 3em 1em;
  }
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`;
