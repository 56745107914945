import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import firebase from "firebase/app";
import "firebase/auth";
import jwt from "jsonwebtoken";
import { useHistory } from "react-router-dom";
import logo from "../assets/logo-black.png";
import Button from "../components/elements/Button";
import CustomInput from "../components/elements/CustomInput";
import AdminContext from "../contexts/AdminContext";

const Login = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(0);
  const history = useHistory();
  const { setAdmin } = useContext(AdminContext);

  useEffect(() => {
    const admin = localStorage.getItem("auth");

    jwt.verify(
      admin,
      "d6d82b79-5226-454c-a36d-17bc13bcd6f2",
      async (err, decoded) => {
        if (decoded) {
          history.push("/");
        }
      }
    );
  }, []);

  const login = async (email, pwd) => {
    setError(null);

    if (email && pwd) {
      try {
        const res = await firebase
          .auth()
          .signInWithEmailAndPassword(email, pwd);
        if (res.user.uid) {
          const db = firebase.firestore();

          await db
            .collection("admins")
            .doc(res.user.uid)
            .get()
            .then((doc) => {
              const admin = doc.data();
              admin.id = doc.id;

              const token = jwt.sign(
                admin,
                "d6d82b79-5226-454c-a36d-17bc13bcd6f2"
              );
              localStorage.setItem("auth", token);
              history.push("/");
              setAdmin(admin);
            })
            .catch((e) => {
              console.log(e);
            });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <Container>
      <img src={logo} alt="logo" />
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={Yup.object({
          email: Yup.string().email("Email invalide").required("Obligatoire"),
          password: Yup.string().required("Obligatoire"),
        })}
        onSubmit={async (data) => {
          setLoading(true);
          await login(data.email, data.password);
          setLoading(false);
        }}
      >
        {() => (
          <Form className="form">
            <CustomInput
              label="Email"
              name="email"
              id="email"
              type="email"
              placeholder="Email"
            />
            <CustomInput
              label="Mot de passe"
              name="password"
              id="password"
              type="password"
              placeholder="Mot de passe"
            />
            <p className="error">{error}</p>
            <Button
              title={loading ? "Se Connecter..." : "Se Connecter"}
              width="large"
              color="#3B4F64"
              type="submit"
            />
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Login;

const Container = styled.div`
  background-color: rgb(247, 247, 249);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  form {
    margin-top: 0.75em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  input {
    width: 320px;
  }
  .error {
    font-size: 12px;
    color: red;
    font-weight: 600;
    text-align: left;
    width: 100%;
  }
  img {
    max-width: 160px;
  }
`;
