import React from "react";
import styled from "styled-components";
import { ReactComponent as Icone } from "../assets/ic.svg";

const DashStatsCart = ({ title, newNumber, oldNumber, color }) => {
  return (
    <Container color={color}>
      <h4 className="h4-cart">{title}</h4>
      <div className="cart-content">
        <div className="left-side">
          <h2>{newNumber}</h2>
          <span className="span-wrp">
            {newNumber >= 2 ? "Réservations" : "Réservation"}
          </span>
        </div>
        <Icone />
      </div>
      <p>{oldNumber}</p>
    </Container>
  );
};
export default DashStatsCart;

const Container = styled.div`
  padding: 1.5em;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  background: #fff;
  .cart-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5em;
  }
  h4 {
    padding-bottom: 0.25em;
    color: ${(props) => (props.color ? props.color : "#000")};
    border-bottom: 2px solid ${(props) => (props.color ? props.color : "#000")};
  }
  h2 {
    font-size: 36px;
    margin-top: 0.25em;
    color: ${(props) => (props.color ? props.color : "#000")};
  }
  svg {
    width: 40px;
    height: 40px;
  }
  .span-wrp {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 1.6em;
    color: ${(props) => (props.color ? props.color : "#000")};
  }
  p {
    margin: 0.5em 0 0 0;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 1.6em;
    color: #bdbdbd;
  }
`;
