import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import styled from "styled-components";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import CustomInput from "./elements/CustomInput";

const Reservations = () => {
  const history = useHistory();
  const [reservations, setReservations] = useState([]);
  const [ville, setVille] = useState("");
  const [client, setClient] = useState("");
  const db = firebase.firestore();
  let isMounted = true;

  const handleFilter = () => {
    if (!ville && !client) return reservations;
    if (ville && !client)
      return reservations.filter((reservation) =>
        reservation?.roomCity?.toLowerCase().includes(ville?.toLowerCase())
      );

    if (!ville && client)
      return reservations.filter((reservation) =>
        reservation?.type_client?.toLowerCase().includes(client?.toLowerCase())
      );
    if (ville && client)
      return reservations.filter(
        (reservation) =>
          reservation?.type_client
            ?.toLowerCase()
            .includes(client?.toLowerCase()) &&
          reservation?.roomCity?.toLowerCase().includes(ville?.toLowerCase())
      );
  };
  const filteredData = handleFilter();
  const getDemandes = async () => {
    const tempArray = [];
    await db
      .collection("reservations")
      .limit(10)
      .get()
      .then(async (snapshot) => {
        await Promise.all(
          snapshot.docs.map(async (doc) => {
            const item = doc.data();
            item.id = doc.id;

            await db
              .collection("users")
              .doc(item.userId)
              .get()
              .then((doc) => {
                const user = doc.data();
                item.name = user.fname + " " + user.lname;
                item.type_client = user?.type_client;
              })
              .catch((e) => {
                console.log(e);
              });

            await db
              .collection("rooms")
              .doc(item.roomId)
              .get()
              .then((doc) => {
                const room = doc.data();
                item.roomImg = room.images[0];
                item.roomName = room.name;
                item.roomType = room.type;
                item.roomCity = room.ville;
              })
              .catch((e) => {
                console.log(e);
              });
            tempArray.push(item);
          })
        );
        if (isMounted) {
          setReservations(tempArray);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getDate = (dates) => {
    const arr = [];
    // dates.map((item) => {
    // arr.push(item.from.toDate().getTime());
    // arr.push(item.to.toDate().getTime());
    //});
    // arr.sort();
    //const from = new Date(arr[0]).toLocaleDateString("fr-CA");
    //const to = new Date(arr[arr.length - 1]).toLocaleDateString("fr-CA");

    const str = "hello";
    return str;
  };

  useEffect(() => {
    isMounted = true;
    getDemandes();

    return () => {
      isMounted = false;
    };
  }, []);

  const filteredDataArr = reservations?.map((reservation) => {
    return {
      ...reservation,
      date: reservation?.day,
      title: reservation?.roomName,
    };
  });

  const handleDateClick = (arg) => {
    // bind with an arrow function

    history.push({
      pathname: "/reservations-details",
      state: { ...arg?.event?._def },
    });
  };

  return (
    <Container>
      <h1>Tableux Dernières Demandes</h1>
      <div className="filters">
        <div className="input-container">
          <label>Type Client:</label>
          <select
            onChange={(e) => setClient(e.target.value)}
            type="text"
            className="filter-input"
            value={client}
          >
            <option value="">Tout</option>
            <option value="externe">Externe</option>
            <option value="interne">Interne</option>
          </select>
        </div>
        <div className="input-container">
          <label>Ville:</label>
          <input
            onChange={(e) => setVille(e.target.value)}
            type="text"
            className="filter-input"
            value={ville}
          />
        </div>
      </div>
      <div className="table">
        <div className="grid">
          <h6>Salle</h6>
          <h6>Prénom & nom</h6>
          <h6>Date réservée</h6>
          <h6>Type de l&apos;évenement</h6>

          {filteredData?.map((item, index) => (
            <Fragment key={index}>
              <h6>Salle {item.roomName}</h6>
              <h5>{item.name}</h5>
              <h5 className="date">{item?.day + " " + item?.time}</h5>
              <h5>{item.roomType}</h5>
            </Fragment>
          ))}
        </div>
      </div>
      <h1>calendrier Dernières Demandes</h1>
      <FullCalendar
        locale="fr"
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        locales={["fr"]}
        events={filteredDataArr.length ? filteredDataArr : []}
        eventClick={handleDateClick}
      />
    </Container>
  );
};

export default Reservations;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 2em 2em 0 2em;
  margin-top: 1.75em;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  max-width: calc(100vw - 4em);
  h1 {
    color: #3b4f64;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: 1em;
  }
  h5 {
    font-size: 14px;
    font-weight: 700;
    color: #202862;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
    position: relative;
    text-transform: capitalize;
  }
  h6 {
    font-size: 13px;
    font-weight: 600;
    color: #3b4f64;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  .name {
    text-transform: capitalize;
  }
  .table {
    overflow-x: auto;
    width: 100%;
    max-width: calc(100vw - 4em);
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr 1fr;
    grid-template-rows: auto;
    padding-bottom: 4em;
  }
  .btn {
    background: #f84b60;
    color: #fff;
    padding: 5px 7px;
    border-radius: 7px;
    text-align: center;
    width: 100px;
    font-size: 13px;
    justify-content: center;
    cursor: pointer;
  }
  .green {
    background: #56e78e;
  }
  .icons {
    justify-content: center;
    padding: 0;
    svg {
      cursor: pointer;
      margin: 10px;
    }
  }
  button {
    font-size: 12px !important;
    font-weight: 500 !important;
    margin-top: 0 !important;
  }
  img {
    width: 120px;
    height: 80px;
    border-radius: 20px;
    object-fit: cover;
    margin-right: 1em;
  }
  .date {
    color: red;
  }
  .filters {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    margin-right : 20px;

  }
  .input-container > label {
    font-size: 14px;
    font-weight: 600;
    color: #4d4d4d;
    margin-bottom: 0.35em;
    margin-top: 1.5em;
  }
  }
  select {
    width: 200px;
  }
  .input-container > input , .input-container > select {
    padding: 10px;
    font-size: 14px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.07);

    margin-bottom: 0.35em;
  }
`;
