import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Account from "./pages/Account";
import AddRoom from "./pages/AddRoom";
import EditUser from "./pages/EditUser";
import Reservations from "./pages/Reservations";
import ReservationsDetails from "./pages/ReservationsDetails";
import Rooms from "./pages/Rooms";
import Tools from "./pages/Tools";
import Users from "./pages/Users";
import EditRoom from "./pages/EditRoom";
import { AdminProvider } from "./contexts/AdminContext";

const App = () => {
  return (
    <AdminProvider>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path={["/", "/dashboard"]} component={Dashboard} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/add-room" component={AddRoom} />
          <Route exact path="/edit-user" component={EditUser} />
          <Route exact path="/reservations" component={Reservations} />
          <Route exact path="/rooms" component={Rooms} />
          <Route exact path="/tools" component={Tools} />
          <Route exact path="/users" component={Users} />
          <Route exact path="/edit-room" component={EditRoom} />
          <Route
            exact
            path="/reservations-details"
            component={ReservationsDetails}
          />
        </Switch>
      </Router>
    </AdminProvider>
  );
};

export default App;
