import React, { useState } from "react";
import styled from "styled-components";
import Profile from "./Profile";

const FirstStep = ({ data }) => {
  const [isActive, setIsActive] = useState(0);

  const getDate = (d) => {
    const dt = new Date(d);
    const time = dt.toLocaleTimeString("en-US", { hour12: false }).split(":");

    const date = dt.toLocaleDateString("fr-CA") + " " + time[0] + "h" + time[1];

    return date;
  };
  console.log(data);

  return (
    <Container>
      <div className="col">
        <h3>Infomation de salle</h3>
        <div className="row">
          <h5>Salle choisie</h5>
          <h6>Salle {data.title}</h6>
        </div>
        <div className="row">
          <h5>Format du Salle</h5>
          <h6>{data?.extendedProps?.format}</h6>
        </div>
        <div className="row">
          <h5>Nombre de participants</h5>
          <h6>{data?.extendedProps?.roomNbr}</h6>
        </div>
        <div className="row">
          <h5>Type du Réservation</h5>
          <h6>{data?.extendedProps?.reservation_type}</h6>
        </div>
        {data?.extendedProps?.payment && (
          <div className="row">
            <h5>Mode de paiement</h5>
            <h6>{data?.extendedProps?.payment}</h6>
          </div>
        )}
        <h3>Demandeur</h3>
        <div className="row profile-row">
          <img
            src={data?.extendedProps?.user?.profile}
            alt="user"
            className="profile"
          />
          <div className="name">
            <h5>
              {data?.extendedProps?.user?.fname +
                " " +
                data?.extendedProps?.user?.lname}
            </h5>
            <button onClick={() => setIsActive(1)}>Voir le profil</button>
          </div>
        </div>
        <div className="row date-row">
          <h3>Dates réservée</h3>
          <h6 className="date">{data.extendedProps?.day}</h6>
        </div>
        <div className="row">
          <h5 className="label">Date</h5>
          <h5 className="label">Description</h5>
        </div>
        <div className="row">
          <h6 className="date-h6">
            {data.extendedProps?.day} {data.extendedProps?.time}
          </h6>
          <h6 className="date-h6">{data?.extendedProps?.comment}</h6>
        </div>
      </div>

      <div className="col">
        <img src={data?.extendedProps?.roomImg} alt="room" className="img" />
        <h3>Besoins techniques</h3>
        <div className="row">
          <h5 className="label">élements</h5>
          <h5 className="label">Qté</h5>
        </div>
        {data?.extendedProps?.needs?.map((item, index) => (
          <div className="row" key={index}>
            <h6>{item.elem}</h6>
            <h6>{item.qte}</h6>
          </div>
        ))}
      </div>
      {data?.extendedProps?.reservation_type === "formation" && (
        <p>
          <strong>Prix : {data?.extendedProps?.price} dhs</strong>
        </p>
      )}

      {isActive ? (
        <Profile setIsActive={setIsActive} data={data?.extendedProps} />
      ) : null}
    </Container>
  );
};

export default FirstStep;

const Container = styled.div`
  padding: 0 2em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5em;
  margin: auto;

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  h3 {
    color: #5276ea;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0.75em;
    margin-top: 2.25em;
  }
  .profile {
    width: 68px;
    border-radius: 100px;
    height: 68px;
    object-fit: cover;
    margin-right: 1em;
  }
  .img {
    width: 100%;
    border-radius: 20px;
    margin-top: 2em;
    max-width: 450px;
    max-height: 270px;
    object-fit: cover;
  }
  h6 {
    color: #3b4f64;
    font-size: 15px;
    font-weight: 500;
    margin: 0.3em 0;
    text-transform: capitalize;
  }
  h5 {
    color: #3b4f64;
    font-size: 15px;
    font-weight: 600;
    margin: 0.3em 0;
    text-transform: capitalize;
  }
  .profile-row,
  .date-row {
    display: flex;
    align-items: center;
  }
  .date-row h6 {
    margin-top: 2.15em;
    margin-left: 1em;
  }
  .date {
    color: red;
    font-weight: 600;
  }
  .name button {
    color: #174cf6;
    background: transparent;
    font-size: 12px;
    font-weight: 600;
    text-decoration: underline;
  }
  .label {
    padding-bottom: 0.5em;
    border-bottom: 1px solid #f1f1f1;
  }
  .date-h6 {
    font-size: 13px;
  }
  @media only screen and (max-width: 1280px) {
    grid-template-columns: 1fr;
  }
  @media only screen and (max-width: 900px) {
    padding: 0;
  }
`;
