import React, { Fragment, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import styled from "styled-components";
import Layout from "../layouts/DefaultLayout";
import AdminContext from "../contexts/AdminContext";
import Button from "../components/elements/Button";

const Rooms = () => {
  const [rooms, setRooms] = useState([]);
  const history = useHistory();
  const { admin } = useContext(AdminContext);
  const db = firebase.firestore();
  let isMounted = true;

  const getRooms = async () => {
    const tempArray = [];
    await db
      .collection("rooms")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const room = doc.data();
          room.id = doc.id;
          tempArray.push(room);
        });
        if (isMounted) {
          setRooms(tempArray);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    isMounted = true;
    getRooms();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Layout>
      <Container>
        <Button
          title="Ajouter une salle"
          radius="7px"
          handleClick={() =>
            history.push({
              pathname: "/add-room",
            })
          }
        />
        <div className="table">
          <div className="grid">
            <h6>Salle</h6>
            <h6>Capacité</h6>
            <h6>Type de salle</h6>
            <h6>Ville</h6>
            <h6 />
            {rooms.map((item, index) => (
              <Fragment key={index}>
                <h5 className="name">{item.name}</h5>
                <h5>{item.capacite}</h5>
                <h5>{item.type}</h5>
                <h5>{item?.ville}</h5>
                <h5 className="icons">
                  <Button
                    title="Plus de détails"
                    handleClick={() =>
                      history.push({
                        pathname: "/edit-room",
                        state: item.id,
                      })
                    }
                    radius="10px"
                    padding="4px 10px"
                  />
                </h5>
              </Fragment>
            ))}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Rooms;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 2em 2em 0em 2em;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  h5 {
    font-size: 14px;
    font-weight: 700;
    color: #202862;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1.25em 0.5em 1.25em 0;
    border-bottom: 1px solid #dfe0eb;
    position: relative;
  }
  h6 {
    font-size: 13px;
    font-weight: 600;
    color: #3b4f64;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  .name {
    text-transform: capitalize;
  }
  .table {
    overflow-x: auto;
    margin-top: 2em;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 120px;
    grid-template-rows: auto;
    padding-bottom: 4em;
  }
  .btn {
    background: #f84b60;
    color: #fff;
    padding: 5px 7px;
    border-radius: 7px;
    text-align: center;
    width: 100px;
    font-size: 13px;
    justify-content: center;
    cursor: pointer;
  }
  .green {
    background: #56e78e;
  }
  .icons {
    justify-content: center;
    padding: 0;
    svg {
      cursor: pointer;
      margin: 10px;
    }
  }
  button {
    font-size: 12px !important;
    font-weight: 500 !important;
    margin-top: 0 !important;
  }
`;
