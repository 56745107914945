import React from "react";
import styled from "styled-components";

const Button = ({
  title,
  width,
  type,
  margin,
  radius,
  padding,
  font,
  handleClick,
  disabled,
  color,
}) => {
  return (
    <Container
      width={width}
      margin={margin}
      radius={radius}
      padding={padding}
      font={font}
      color={color}
    >
      <button type={type} onClick={handleClick} disabled={disabled}>
        {title || (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="#fff"
            style={{ marginTop: "1px" }}
          >
            <path d="M10 9.408l2.963 2.592-2.963 2.592v-1.592h-8v-2h8v-1.592zm-2-4.408v4h-8v6h8v4l8-7-8-7zm6-3c-1.787 0-3.46.474-4.911 1.295l.228.2 1.396 1.221c1.004-.456 2.114-.716 3.287-.716 4.411 0 8 3.589 8 8s-3.589 8-8 8c-1.173 0-2.283-.26-3.288-.715l-1.396 1.221-.228.2c1.452.82 3.125 1.294 4.912 1.294 5.522 0 10-4.477 10-10s-4.478-10-10-10z" />
          </svg>
        )}
      </button>
    </Container>
  );
};

export default Button;

const Container = styled.div`
  width: ${(props) => (props.width === "large" ? "100%" : "auto")};
  font-family: inherit;
  button {
    font-family: inherit;
    font-size: ${(props) => (props.font ? props.font : "15px")};
    font-weight: 500;
    background: ${(props) => (props.color ? props.color : "#5276EA")};
    color: #fff;
    padding: ${(props) => (props.padding ? props.padding : "8px 22px")};
    border-radius: ${(props) => (props.radius ? props.radius : "100px")};
    border: 2px solid ${(props) => (props.color ? props.color : "#5276EA")};
    cursor: pointer;
    transition: all 0.5s;
    margin-top: ${(props) => (props.margin ? props.margin : "2.5em")};
    width: ${(props) => (props.width === "large" ? "100%" : "auto")};
    &:hover {
      background: transparent;
      color: ${(props) => (props.color ? props.color : "#5276EA")};
      svg {
        fill: ${(props) => (props.color ? props.color : "#5276EA")};
      }
    }
    &:disabled {
      background-color: #b5b5b5;
      cursor: not-allowed;
      border: none;
    }
  }
`;
