import React, { Fragment, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Layout from "../layouts/DefaultLayout";
import Button from "../components/elements/Button";
import CustomInput from "../components/elements/CustomInput";

const Tools = () => {
  const [tools, setTools] = useState([]);
  const [forceupdate, setForceupdate] = useState(false);
  const db = firebase.firestore();
  let isMounted = true;

  const getTools = async () => {
    const tempArray = [];
    await db
      .collection("tools")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const tool = doc.data();
          tool.id = doc.id;
          tempArray.push(tool);
        });
        if (isMounted) {
          setTools(tempArray);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const addTool = async (name) => {
    await db
      .collection("tools")
      .add({
        name,
      })
      .then(() => {
        const arr = tools;
        arr.push({ name: name });
        setTools(arr);
        setForceupdate(!forceupdate);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteTool = async (id) => {
    await db
      .collection("tools")
      .doc(id)
      .delete()
      .then(() => {
        let arr = tools;
        arr = arr.filter((item) => item.id !== id);
        setTools(arr);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    isMounted = true;
    getTools();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Layout>
      <Container>
        <h4>Matériel technique</h4>
        <Formik
          initialValues={{ name: "" }}
          validationSchema={Yup.object({
            name: Yup.string().required("Obligatoire"),
          })}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            console.log("ddd");
            await addTool(data.name);
            setSubmitting(false);
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form className="form">
              <div className="row">
                <CustomInput label="" name="name" id="name" type="text" />
                <Button
                  handleClick={handleSubmit}
                  title={isSubmitting ? "Ajouter..." : "Ajouter"}
                  radius="7px"
                  padding="10.5px 26px"
                  type="submit"
                />
              </div>
            </Form>
          )}
        </Formik>

        <div className="table">
          <div className="grid">
            <h6>#</h6>
            <h6>Nom</h6>
            <h6 />
            {tools.map((item, index) => (
              <Fragment key={index}>
                <h5 className="name">{index + 1}</h5>
                <h5>{item.name}</h5>
                <h5 className="icons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="#f84b60"
                    onClick={() => deleteTool(item.id)}
                  >
                    <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
                  </svg>
                </h5>
              </Fragment>
            ))}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Tools;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 2em 2em 0em 2em;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  h5 {
    font-size: 14px;
    font-weight: 700;
    color: #202862;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1.25em 0.5em 1.25em 0;
    border-bottom: 1px solid #dfe0eb;
    position: relative;
    text-transform: capitalize;
  }
  h6 {
    font-size: 13px;
    font-weight: 600;
    color: #3b4f64;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  .name {
    text-transform: capitalize;
  }
  .table {
    overflow-x: auto;
    margin-top: 3em;
    max-width: 600px;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 60px;
    grid-template-rows: auto;
    padding-bottom: 4em;
  }
  .btn {
    background: #f84b60;
    color: #fff;
    padding: 5px 7px;
    border-radius: 7px;
    text-align: center;
    width: 100px;
    font-size: 13px;
    justify-content: center;
    cursor: pointer;
  }
  .green {
    background: #56e78e;
  }
  .icons {
    justify-content: center;
    padding: 0;
    svg {
      cursor: pointer;
      margin: 10px;
    }
  }
  button {
    font-size: 12px !important;
    font-weight: 500 !important;
    margin-top: 0 !important;
  }
  h4 {
    color: #202862;
    margin-bottom: 1em;
  }
  .row {
    display: flex;
    align-items: center;
    width: fit-content;
    button {
      margin: 0;
      margin-left: 1em;
    }
    input {
      margin: 0;
    }
    label {
      display: none;
    }
  }
  @media only screen and (max-width: 460px) {
    padding: 2em 0.5em 0em 0.5em;
    input {
      min-width: 210px;
    }
    button {
      margin-left: 0.5em !important;
    }
  }
`;
