import React, { Fragment, useState, useEffect } from "react";

import styled from "styled-components";

const ThirdStep = ({ items, paid }) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let t = 0;
    items.map((item) => {
      t += item.total;
    });
    setTotal(t);
  }, []);

  return (
    <Container>
      <div className="table">
        <div className="grid">
          <h6>Désignation</h6>
          <h6>Prix Unitair</h6>
          <h6>Quantité</h6>
          <h6>Total</h6>
          {items.map((item, index) => (
            <Fragment key={index}>
              <h5>{item.name}</h5>
              <h5>{item.price} DH</h5>
              <h5>{item.quantity}</h5>
              <h5>{item.total} DH</h5>
            </Fragment>
          ))}
        </div>
      </div>

      <div className="price-row">
        <div className="price-row-wrp">
          <p className="blue">Prix Hors Taxe </p>
          <p className="total">{total} dhs </p>
        </div>
        <div className="price-row-wrp">
          <p className="blue">TVA</p>
          <p className="total">20% </p>
        </div>
        <div className="price-row-wrp">
          <p className="blue">Total Net à payer TTC </p>
          <p className="total bold">{total + total * 0.2} dhs </p>
        </div>
      </div>

      <h4 className="paid">Statut de paiement:</h4>
      {paid ? (
        <h4 className="paid-txt">Le paiement a été effectué.</h4>
      ) : (
        <h4 className="paid-txt red">
          Le paiement n&apos;a pas encore été effectué.
        </h4>
      )}
    </Container>
  );
};

export default ThirdStep;

const Container = styled.div`
  max-width: 1200px;
  padding: 0 2em;
  .page-container {
    padding: 0 1em 1em 1em;
    border-radius: 10px;
    background: #fff;
    box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
      rgb(237 239 247 / 47%) 0px 0px 0px;
  }
  .form {
    width: 100%;
    margin-top: 2em;
    padding: 2em 0;
    border-radius: 20px;
    background: #fff;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    margin-bottom: 2em;
  }
  h6 {
    color: rgb(128, 128, 128);
    font-weight: 600;
    font-size: 13px;
    text-transform: capitalize;
    color: #3b4f64;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  h5 {
    color: rgb(128, 128, 128);
    font-size: 13px;
    font-weight: 600;
    color: #202862;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
    text-transform: capitalize;
  }
  .table {
    overflow-x: auto;
    max-width: 1200px;
  }
  .grid {
    display: grid;
    grid-template-columns: 2.2fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    border-bottom: 1px solid #dfe0eb;
    max-width: 1200px;
    width: 100%;
  }
  input {
    min-width: 70px;
    margin: 1em 0.5em 1em 0;
    width: calc(100% - 0.5em);
    border-radius: 5px !important;
  }
  label {
    display: none;
  }
  button {
    margin: 1em 0;
    width: 100% !important;
  }
  .delete {
    justify-content: center;
    padding: 0;
    svg {
      cursor: pointer;
    }
  }
  p {
    font-size: 11px;
    color: red;
    font-weight: 600;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    button {
      margin-top: 0;
      margin-bottom: 2.2em;
    }
  }
  .confirm {
    background: transparent !important;
    border-radius: 7px;
    border: 1.5px solid #187bcd;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .edit-form {
    input {
      border: 1.5px solid #187bcd;
    }
  }
  .price-row {
    display: flex;
    width: 100%;
    align-items: flex-end;
    margin-top: 1.5em;
    margin-bottom: 3em;
    flex-direction: column;
  }
  .price-row-wrp {
    display: grid;
    grid-template-columns: 200px 100px;
  }
  .blue {
    font-size: 15px;
    font-weight: 600;
    color: #5276ea;
    margin-right: 2em;
    margin-top: 1em;
  }
  .total {
    font-size: 15px;
    font-weight: 500;
    color: #202862;
    margin-top: 1em;
  }
  .bold {
    font-weight: 700;
  }
  .paid {
    font-size: 16px;
    font-weight: 600;
    color: #202862;
    margin-bottom: 1em !important;
  }
  .paid-txt {
    color: green;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 1em !important;
  }
  .red {
    color: #c30a1d;
    font-size: 16px;
    font-weight: 600;
  }
  @media only screen and (max-width: 900px) {
    padding: 0;
  }
  @media only screen and (max-width: 576px) {
    .price-row {
      align-items: flex-start;
    }
    .price-row-wrp {
      display: grid;
      grid-template-columns: 180px 100px;
    }
  }
`;
