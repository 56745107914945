import React from "react";
import styled from "styled-components";

const Steps = ({ step }) => {
  return (
    <Container>
      <div className={step >= 1 ? "nbr active-step" : "nbr"}>
        <div className="bg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill={step >= 1 ? "#fff" : "#C4C4C4"}
          >
            <path d="M0 11.522l1.578-1.626 7.734 4.619 13.335-12.526 1.353 1.354-14 18.646z" />
          </svg>
        </div>
        <span>Validation</span>
      </div>
      <hr />
      <div className={step >= 2 ? "nbr active-step" : "nbr"}>
        <div className="bg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill={step >= 2 ? "#fff" : "#C4C4C4"}
          >
            <path d="M0 11.522l1.578-1.626 7.734 4.619 13.335-12.526 1.353 1.354-14 18.646z" />
          </svg>
        </div>
        <span>Devis</span>
      </div>
      <hr />
      <div className={step >= 3 ? "nbr active-step" : "nbr"}>
        <div className="bg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill={step >= 3 ? "#fff" : "#C4C4C4"}
          >
            <path d="M0 11.522l1.578-1.626 7.734 4.619 13.335-12.526 1.353 1.354-14 18.646z" />
          </svg>
        </div>
        <span>Paiement</span>
      </div>
    </Container>
  );
};

export default Steps;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 3em;
  width: 100%;
  .nbr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
  hr {
    width: calc((100% - 180px) / 2);
    background: #c4c4c4;
    height: 2px;
    border: none;
  }
  .bg {
    width: 60px;
    height: 60px;
    font-size: 20px;
    font-weight: 600;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #c4c4c4;
    margin-bottom: 0.5em;
  }
  .active-step .bg {
    background: #3b4f64;
  }
  .active-step span {
    color: #3b4f64;
  }
  span {
    font-size: 14px !important;
    color: #c4c4c4;
    font-weight: 600;
    text-align: center;
    position: absolute;
    bottom: -1em;
  }
`;
