import React, { useState, useContext } from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import firebase from "firebase/app";
import "firebase/firestore";
import styled from "styled-components";
import Button from "../components/elements/Button";
import Layout from "../layouts/DefaultLayout";
import CustomInput from "../components/elements/CustomInput";
import AdminContext from "../contexts/AdminContext";

const EditAdmin = () => {
  const [error, setError] = useState(0);
  const history = useHistory();
  const { admin, setAdmin } = useContext(AdminContext);
  const db = firebase.firestore();

  const update = async (data) => {
    setError(0);

    await db
      .collection("admins")
      .doc(admin.id)
      .update({
        email: data.email,
        username: data.username,
      })
      .then(() => {
        if (!data.pwd && !data.email) {
          setAdmin({ ...admin, email: data.email, username: data.username });
          alert("Vos informations ont été mises à jour avec succès");
          history.push("/");
        }
      });

    if (data.email) {
      const currentUser = firebase.auth().currentUser;
      currentUser
        .updateEmail(data.email)
        .then(() => {
          alert("Vos informations ont été mises à jour avec succès");
          history.push("/");
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (data.pwd) {
      const currentUser = firebase.auth().currentUser;
      currentUser
        .updatePassword(data.pwd)
        .then(() => {
          alert("Vos informations ont été mises à jour avec succès");
          history.push("/");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Layout>
      <Container>
        <Formik
          enableReinitialize
          initialValues={{
            email: admin?.email || "",
            pwd: "",
            username: admin?.username || "",
          }}
          validationSchema={Yup.object({
            email: Yup.string().email("Email invalid").required("required"),
            pwd: Yup.string().min(6, "must be more than 6 characters"),
            username: Yup.string(),
          })}
          onSubmit={async (data, { setSubmitting }) => {
            setSubmitting(true);
            await update(data);
            setSubmitting(false);
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Form className="form">
              <h3>Profile</h3>
              <CustomInput
                margin="0 0.5em"
                label="Username"
                name="username"
                id="username"
                type="text"
                placeholder="Admin"
              />
              <CustomInput
                margin="0 0.5em"
                label="Email"
                name="email"
                id="email"
                type="text"
                placeholder="mohammed@gmail.com"
              />
              <CustomInput
                margin="0 0.5em"
                label="Mot de passe"
                name="pwd"
                id="pwd"
                type="password"
                placeholder="Mot de passe"
              />
              {error ? <p className="error">{error}</p> : null}
              <div className="btn-wrp">
                <Button
                  handleClick={handleSubmit}
                  title={isSubmitting ? "Modifer..." : "Modifer"}
                  type="submit"
                  radius="7px"
                />
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  );
};

export default EditAdmin;

const Container = styled.div`
  background: #fff;
  width: 100%;
  max-width: 650px;
  margin: auto;
  margin-top: 2em;
  padding: 0em 0em 1em 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  .form {
    padding: 2em;
    width: 100%;
  }
  input[type="email"],
  input[type="password"] {
    width: 100%;
  }
  input,
  textarea {
    width: 100% !important;
  }
  h3 {
    width: 100%;
    color: #3b4f64 !important;
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 0.5em;
    border-bottom: 4px solid #3b4f64;
    margin-bottom: 2em;
  }
  .btn {
    position: relative;
    background: #fff;
    padding: 10px 12px;
    border-radius: 7px;
    font-size: 14px;
    display: flex;
    border: 1px solid #f84b60;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    margin-top: 0.75em;
    margin-bottom: 1.5em;
    color: #f84b60;
    cursor: pointer;
  }
  p {
    padding: 0.75em 1em;
    text-align: justify;
    color: #5a6169;
  }
  .btn-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .error {
    font-size: 12px;
    color: red;
    font-weight: 600;
    margin-top: 0.1em;
    padding-left: 0;
  }
`;
