import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import styled from "styled-components";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import Layout from "../layouts/DefaultLayout";
import Button from "../components/elements/Button";

const Reservations = () => {
  const [reservations, setReservations] = useState([]);
  const history = useHistory();
  const db = firebase.firestore();
  let isMounted = true;

  const filteredData = reservations?.map((reservation) => {
    return {
      ...reservation,
      date: reservation?.day,
      title: reservation?.roomName,
    };
  });
  const handleDateClick = (arg) => {
    // bind with an arrow function
    console.log(arg);
    history.push({
      pathname: "/reservations-details",
      state: { ...arg?.event?._def },
    });
  };
  const getDemandes = async () => {
    const tempArray = [];
    await db
      .collection("reservations")
      .get()
      .then(async (snapshot) => {
        await Promise.all(
          snapshot.docs.map(async (doc) => {
            const item = doc.data();
            item.id = doc.id;

            // item.date = getDate(item.dates);

            await db
              .collection("users")
              .doc(item.userId)
              .get()
              .then((doc) => {
                const user = doc.data();
                user.birthDate = user.birthDate.toDate().getTime();
                item.user = user;
              })
              .catch((e) => {
                console.log(e);
              });

            await db
              .collection("rooms")
              .doc(item.roomId)
              .get()
              .then((doc) => {
                const room = doc.data();
                item.roomImg = room.images[0];
                item.roomName = room.name;
                item.roomType = room.type;
                item.roomNbr = room.places;
              })
              .catch((e) => {
                console.log(e);
              });
            tempArray.push(item);
          })
        );
        if (isMounted) {
          setReservations(tempArray);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getDate = (dates) => {
    const arr = [];
    dates.map((item) => {
      arr.push(item.from);
      arr.push(item.to);
    });
    arr.sort();
    const from = new Date(arr[0]).toLocaleDateString("fr-CA");
    const to = new Date(arr[arr.length - 1]).toLocaleDateString("fr-CA");

    const str = "De " + from + " à " + to;
    return str;
  };

  useEffect(() => {
    isMounted = true;
    getDemandes();

    return () => {
      isMounted = false;
    };
  }, []);

  console.log(filteredData);

  return (
    <Layout>
      <Container>
        <FullCalendar
          locale="fr"
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          locales={["fr"]}
          events={filteredData?.length ? filteredData : []}
          eventClick={handleDateClick}
        />

        {/* <div className="table">
          <div className="grid">
            <h6>Salle</h6>
            <h6>Prénom & nom</h6>
            <h6>Date réservée</h6>
            <h6>Type de salle</h6>
            <h6 />
            {reservations.map((item, index) => {
              console.log(item);
              return (
                <Fragment key={index}>
                  <h5
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <img src={item.roomImg} alt="img" />
                    <p style={{ marginTop: 5 }}> {item.roomName}</p>
                  </h5>
                  <h5>{item.user?.fname + " " + item.user?.lname}</h5>
                  <h5 className="date">{item.day + " " + item.time}</h5>
                  <h5>{item.roomType}</h5>
                  <h5 className="icons">
                    <Button
                      title="Plus de détails"
                      handleClick={() =>
                        history.push({
                          pathname: "/reservations-details",
                          state: item,
                        })
                      }
                      radius="10px"
                      padding="4px 10px"
                    />
                  </h5>
                </Fragment>
              );
            })}
          </div>
        </div> */}
      </Container>
    </Layout>
  );
};

export default Reservations;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 1em 2em 0em 2em;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  h5 {
    font-size: 14px;
    font-weight: 700;
    color: #202862;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
    position: relative;
    text-transform: capitalize;
  }
  h6 {
    font-size: 13px;
    font-weight: 600;
    color: #3b4f64;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  .name {
    text-transform: capitalize;
  }
  .table {
    overflow-x: auto;
  }
  .grid {
    display: grid;
    grid-template-columns: 2fr 1fr 1.5fr 1fr 120px;
    grid-template-rows: auto;
    padding-bottom: 4em;
  }
  .btn {
    background: #f84b60;
    color: #fff;
    padding: 5px 7px;
    border-radius: 7px;
    text-align: center;
    width: 100px;
    font-size: 13px;
    justify-content: center;
    cursor: pointer;
  }
  .green {
    background: #56e78e;
  }
  .icons {
    justify-content: center;
    padding: 0;
    svg {
      cursor: pointer;
      margin: 10px;
    }
  }
  button {
    font-size: 12px !important;
    font-weight: 500 !important;
    margin-top: 0 !important;
  }
  img {
    width: 120px;
    height: 80px;
    border-radius: 20px;
    object-fit: cover;
    margin-right: 1em;
  }
  .date {
    color: red;
  }
`;
