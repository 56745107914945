import React from "react";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import firebase from "firebase/app";
import "firebase/firestore";
import styled from "styled-components";
import Button from "./elements/Button";
import CustomInput from "./elements/CustomInput";

const CancelPopup = ({ setCancel, id }) => {
  const db = firebase.firestore();
  const history = useHistory();

  const send = async () => {
    await db
      .collection("reservations")
      .doc(id)
      .update({
        status: "Canceled",
      })
      .then(() => {
        alert("La réservation a été annulée avec succès");
        history.push("/reservations");
      });
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{
          object: "",
          message: "",
          type: ["email", "sms"],
        }}
        validationSchema={Yup.object({
          object: Yup.string().required("Obligatoir"),
          message: Yup.string().required("Obligatoir"),
          type: Yup.array().min(1, "Obligatoir").required("Obligatoir"),
        })}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);
          await send(data);
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, isSubmitting, handleChange, errors, values }) => (
          <Form className="form">
            <button className="close" onClick={() => setCancel(0)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                fill="#fff"
              >
                <path
                  d="M15.0327 12L19.5814 7.45136C20.1395 6.89318 20.1395 5.98818 19.5814 5.42955L18.5705 4.41864C18.0123 3.86045 17.1073 3.86045 16.5486 4.41864L12 8.96727L7.45136 4.41864C6.89318 3.86045 5.98818 3.86045 5.42955 4.41864L4.41864 5.42955C3.86045 5.98773 3.86045 6.89273 4.41864 7.45136L8.96727 12L4.41864 16.5486C3.86045 17.1068 3.86045 18.0118 4.41864 18.5705L5.42955 19.5814C5.98773 20.1395 6.89318 20.1395 7.45136 19.5814L12 15.0327L16.5486 19.5814C17.1068 20.1395 18.0123 20.1395 18.5705 19.5814L19.5814 18.5705C20.1395 18.0123 20.1395 17.1073 19.5814 16.5486L15.0327 12Z"
                  fill="#fff"
                />
              </svg>
            </button>
            <h3>Annulation la demande de la réservation</h3>
            <CustomInput
              margin="0 0.5em"
              label="Objet"
              name="object"
              id="object"
              type="text"
              placeholder="Objet"
              required
            />
            <CustomInput
              margin="0 0.5em"
              label="Message"
              name="message"
              id="message"
              type="text"
              placeholder="Message"
              textarea
              required
            />
            <div className="input-row">
              <label className="label">Envoyer par:</label>
              <input
                type="checkbox"
                id="email"
                name="type"
                value="email"
                onChange={handleChange}
                checked={values.type.includes("email")}
              />
              <label htmlFor="email">Email</label>
              <input
                type="checkbox"
                id="sms"
                value="sms"
                name="type"
                onChange={handleChange}
                checked={values.type.includes("sms")}
              />
              <label htmlFor="sms">SMS</label>
            </div>
            {errors.type ? <p className="error">{errors.type}</p> : null}

            <div className="btn-wrp">
              <Button
                handleClick={handleSubmit}
                title={isSubmitting ? "Envoyer..." : "Envoyer"}
                type="submit"
                radius="7px"
              />
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default CancelPopup;

const Container = styled.div`
  top: 0;
  left: 0;
  background-color: rgba(17, 18, 38, 0.6);
  width: 100vw;
  position: fixed;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;

  .form {
    z-index: 5000;
    max-width: 650px;
    background: #fff;
    border-radius: 10px;
    width: 100%;
    padding: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
  input[type="email"],
  input[type="password"] {
    width: 100%;
  }
  input[type="text"],
  textarea {
    width: 100% !important;
  }
  h3 {
    width: 100%;
    color: #3b4f64 !important;
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 0.5em;
    border-bottom: 4px solid #3b4f64;
    margin-bottom: 2em;
  }
  .btn {
    position: relative;
    background: #fff;
    padding: 10px 12px;
    border-radius: 7px;
    font-size: 14px;
    display: flex;
    border: 1px solid #f84b60;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    margin-top: 0.75em;
    margin-bottom: 1.5em;
    color: #f84b60;
    cursor: pointer;
  }
  p {
    padding: 0.75em 1em;
    text-align: justify;
    color: #5a6169;
  }
  .btn-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .error {
    font-size: 12px;
    color: red;
    font-weight: 600;
    margin-top: 0.1em;
    padding-left: 0;
    text-align: left;
    width: 100%;
  }
  .input-row {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 1em;
  }
  label {
    font-size: 14px;
    font-weight: 600;
    color: #4d4d4d;
  }
  input[type="checkbox"] {
    margin-right: 0.3em;
    margin-left: 2em;
    cursor: pointer;
  }
  .close {
    background: transparent;
    top: 0.25em;
    right: -2em;
    position: absolute;
  }
`;
