import React from "react";
import styled from "styled-components";

const Profile = ({ setIsActive, data }) => {
  return (
    <Container>
      <div className="form">
        <button className="close" onClick={() => setIsActive(0)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="18"
            height="18"
            fill="#fff"
          >
            <path
              d="M15.0327 12L19.5814 7.45136C20.1395 6.89318 20.1395 5.98818 19.5814 5.42955L18.5705 4.41864C18.0123 3.86045 17.1073 3.86045 16.5486 4.41864L12 8.96727L7.45136 4.41864C6.89318 3.86045 5.98818 3.86045 5.42955 4.41864L4.41864 5.42955C3.86045 5.98773 3.86045 6.89273 4.41864 7.45136L8.96727 12L4.41864 16.5486C3.86045 17.1068 3.86045 18.0118 4.41864 18.5705L5.42955 19.5814C5.98773 20.1395 6.89318 20.1395 7.45136 19.5814L12 15.0327L16.5486 19.5814C17.1068 20.1395 18.0123 20.1395 18.5705 19.5814L19.5814 18.5705C20.1395 18.0123 20.1395 17.1073 19.5814 16.5486L15.0327 12Z"
              fill="#fff"
            />
          </svg>
        </button>
        <h3>Profile</h3>
        <img src={data.user?.profile} alt="avatar" className="avatar" />
        <div className="sub-form">
          <div className="form-row">
            <div className="input">
              <label>Prénom</label>
              <input type="text" disabled value={data.user.fname} />
            </div>
            <div className="input">
              <label>Nom</label>
              <input type="text" disabled value={data.user.lname} />
            </div>
          </div>
          <div className="form-row">
            <div className="input">
              <label>Email</label>
              <input type="text" disabled value={data.user.email} />
            </div>
            <div className="input">
              <label>Téléphone</label>
              <input type="text" disabled value={data.user.phone} />
            </div>
          </div>

          <div className="form-row">
            <div className="input">
              <label>Date de naissance</label>
              <input
                type="date"
                disabled
                value={new Date(data.user.birthDate).toLocaleDateString(
                  "fr-CA"
                )}
              />
            </div>
            <div className="input" />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Profile;

const Container = styled.div`
  top: 0;
  left: 0;
  background-color: rgba(17, 18, 38, 0.6);
  width: 100vw;
  position: fixed;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
  .form {
    z-index: 5000;
    max-width: 750px;
    background: #fff;
    border-radius: 10px;
    width: 100%;
    padding: 2em 2em 4em 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }
  .sub-form {
    width: 100%;
  }
  input,
  textarea {
    padding: 10px;
    font-size: 14px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    min-width: ${(props) => (props.small ? "170px" : "260px")};
    margin-bottom: 0.35em;
    max-width: ${(props) => (props.small ? "170px" : "unset")};
    width: 100%;
  }
  h3 {
    width: 100%;
    color: #3b4f64 !important;
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 0.5em;
    border-bottom: 4px solid #3b4f64;
    margin-bottom: 2em;
    margin-top: 0 !important;
  }
  label {
    font-size: 14px;
    font-weight: 600;
    color: #4d4d4d;
  }
  .close {
    background: transparent;
    top: 0.25em;
    right: -2em;
    position: absolute;
  }
  .avatar {
    width: 120px;
    height: 120px;
    border-radius: 200px;
    object-fit: cover;
    margin-bottom: 2em;
  }
  .form-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    width: 100%;
    margin-top: 1em;
    grid-gap: 2em;
  }
`;
