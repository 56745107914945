import React, { useState, useEffect } from "react";
import styled from "styled-components";
import firebase from "firebase/app";
import DateDiff from "date-diff";
import "firebase/firestore";
import DashStatsCart from "../components/DashStatsCart";

import Layout from "../layouts/DefaultLayout";
import Demandes from "../components/Demandes";

const Dashboard = () => {
  const [daily, setDaily] = useState([]);
  const [weekly, setWeekly] = useState([]);
  const [monthly, setMonthly] = useState([]);
  const [yearly, setYearly] = useState([]);
  const [lastDaily, setLastDaily] = useState([]);
  const [lastWeekly, setLastWeekly] = useState([]);
  const [lastMonthly, setLastMonthly] = useState([]);
  const [lastYearly, setLastYearly] = useState([]);
  const [lastSevenDays, setLastSevenDays] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [sites, setSites] = useState([]);
  let isMounted = true;
  const db = firebase.firestore();

  useEffect(() => {
    isMounted = true;
    const today = new Date();
    const tempArray = [];
    const sources = [];
    const d = [];
    const ld = [];
    const w = [];
    const lw = [];
    const m = [];
    const lm = [];
    const y = [];
    const ly = [];
    const third = [];
    const fourth = [];
    const fifth = [];
    const sixth = [];
    const seventh = [];
    const src1 = [];
    const src2 = [];
    const src3 = [];
    const src4 = [];
    const src5 = [];
    const src6 = [];
    const src7 = [];

    const unsubscribeListener = db
      .collection("reservations")
      .onSnapshot((querySnapshot) => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach((doc) => {
            const lead = doc.data();
            lead.createdAt = lead.createdAt.toDate();
            tempArray.push(lead);
          });

          tempArray.map((item) => {
            const diff = new DateDiff(today, item.createdAt);

            if (
              diff.days() >= 0 &&
              diff.days() <= 1 &&
              item.createdAt.getDate() === today.getDate()
            ) {
              d.push(item);
              src1.push(item.source);
            }
            if (diff.days() > 0 && diff.days() <= 1) {
              ld.push(item);
              src2.push(item.source);
            }
            if (diff.days() > 1 && diff.days() <= 2) {
              third.push(item);
              src3.push(item.source);
            }
            if (diff.days() > 2 && diff.days() <= 3) {
              fourth.push(item);
              src4.push(item.source);
            }
            if (diff.days() > 3 && diff.days() <= 4) {
              fifth.push(item);
              src5.push(item.source);
            }
            if (diff.days() > 4 && diff.days() <= 5) {
              sixth.push(item);
              src6.push(item.source);
            }
            if (diff.days() > 5 && diff.days() <= 6) {
              seventh.push(item);
              src7.push(item.source);
            }
            if (diff.weeks() >= 0 && diff.weeks() < 1) {
              w.push(item);
            }
            if (diff.weeks() >= 1 && diff.weeks() < 2) {
              lw.push(item);
            }
            if (diff.months() >= 0 && diff.months() < 1) {
              m.push(item);
            }
            if (diff.months() >= 1 && diff.months() < 2) {
              lm.push(item);
            }
            if (diff.years() >= 0 && diff.years() < 1) {
              y.push(item);
            }
            if (diff.years() >= 1 && diff.years() < 2) {
              ly.push(item);
            }
            if (item.source) {
              sources.push(item.source);
            }
          });
          setDaily(d);
          setLastDaily(ld);
          setWeekly(w);
          setLastWeekly(lw);
          setMonthly(m);
          setLastMonthly(lm);
          setYearly(y);
          setLastYearly(ly);
          setLastSevenDays([
            seventh.length,
            sixth.length,
            fifth.length,
            fourth.length,
            third.length,
            ld.length,
            d.length,
          ]);
        }
      });

    return () => {
      unsubscribeListener();
      isMounted = false;
    };
  }, []);

  return (
    <Layout>
      <Container>
        <div className="row row-1">
          <DashStatsCart
            color="#5276EA"
            title="Aujourd'hui"
            newNumber={daily.length}
            oldNumber={`Hier : ${lastDaily.length} Réservations`}
          />
          <DashStatsCart
            color="#5276EA"
            title="Week"
            newNumber={weekly.length}
            oldNumber={`La semaine dernière : ${lastWeekly.length} Réservations`}
          />
          <DashStatsCart
            color="#5276EA"
            title="Month"
            newNumber={monthly.length}
            oldNumber={`Le mois dernier : ${lastMonthly.length} Réservations`}
          />
          <DashStatsCart
            color="#5276EA"
            title="Year"
            newNumber={yearly.length}
            oldNumber={`L'année dernière : ${lastYearly.length} Réservations`}
          />
        </div>
        <Demandes />
      </Container>
    </Layout>
  );
};

export default Dashboard;

const Container = styled.div`
  border-radius: 20px;
  padding: 0 1em;
  display: grid;
  grid-template-rows: auto auto;
  .row-1 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1.75em;
  }
  @media only screen and (max-width: 1300px) {
    .row-1 {
      display: grid;
      grid-template-columns: repeat(2, 1fr) !important;
      grid-template-rows: auto auto;
    }
  }
  @media only screen and (max-width: 900px) {
    .row-1 {
      display: grid;
      grid-template-columns: 100% !important;
      grid-template-rows: auto auto auto auto;
    }
  }
`;
