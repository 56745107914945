import React from "react";
import styled from "styled-components";
import { useField } from "formik";

const CustomInput = ({ small, label, required, name, id, data, isObject }) => {
  const [field, meta] = useField(id);

  return (
    <Container small={small}>
      <label htmlFor={id || name}>
        {label} {required ? <span className="red">*</span> : null}
      </label>
      <div className="input-wrp">
        <select className="input" id={id} {...field}>
          {data.map((item, index) => (
            <option value={isObject ? item.id : item} key={index}>
              {isObject ? item.name : item}
            </option>
          ))}
        </select>

        {meta.touched && meta.error && (
          <div className="error">{meta.error}</div>
        )}
      </div>
    </Container>
  );
};

export default CustomInput;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-size: 14px;
    font-weight: 600;
    color: #4d4d4d;
    margin-bottom: 0.35em;
  }
  select {
    outline: none;
    padding: 10px 3px;
    font-size: 14px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    min-width: ${(props) => (props.small ? "50px" : "260px")};
    margin-bottom: 0.35em;
    max-width: ${(props) => (props.small ? "50px" : "unset")};
  }
  .error {
    font-size: 11px;
    color: red;
    font-weight: 600;
    margin-top: 0.1em;
    margin-left: 0.2em;
  }
  .input-wrp {
    position: relative;
  }
  svg {
    position: absolute;
    top: 14px;
    right: 10px;
    cursor: pointer;
  }
  .red {
    color: red;
  }
`;
