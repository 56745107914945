import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Button from "./elements/Button";

const Header = ({ setMenuIsActive, menuIsActive, path }) => {
  const history = useHistory();

  const getPath = () => {
    let title;
    if (path === "/" || path === "/dashboard") {
      title = "Dashboard";
    } else if (path === "/add-room") {
      title = "Ajouter Salle";
    } else if (path === "/account") {
      title = "Paramètre";
    } else if (path === "/edit-user") {
      title = "Modifier Utilisateur";
    } else if (path === "/reservations") {
      title = "Demandes";
    } else if (path === "/reservations-details") {
      title = "Détail de réservation";
    } else if (path === "/rooms") {
      title = "Salles";
    } else if (path === "/tools") {
      title = "Matériel technique";
    } else if (path === "/users") {
      title = "Utilisateurs";
    } else if (path === "/edit-room") {
      title = "Modifier Salle";
    }

    return title;
  };
  const signOut = () => {
    localStorage.removeItem("auth");
    history.push("/login");
  };
  return (
    <Container>
      {menuIsActive ? (
        <svg
          fill="#fff"
          xmlns="http://www.w3.org/2000/svg"
          height="32"
          viewBox="0 0 24 24"
          width="32"
          className="menu-icon"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
        </svg>
      ) : (
        <svg
          width="28"
          height="28"
          viewBox="0 0 36 21"
          fill="#fff"
          className="menu-icon"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <rect y="18" width="36" height="3" rx="1.5" fill="white" />
          <rect y="9" width="36" height="3" rx="1.5" fill="white" />
          <rect width="36" height="3" rx="1.5" fill="white" />
        </svg>
      )}

      <h1>{getPath()}</h1>

      {path === "/account" ? (
        <div className="header-right-side">
          <Button
            handleClick={signOut}
            radius="7px"
            margin="0"
            font="14px"
            padding="5px 7px"
            color="#5276EA"
          />
        </div>
      ) : null}
    </Container>
  );
};

export default Header;

const Container = styled.div`
  width: calc(100% - 4em);
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 2em;
  position: sticky;
  top: 0;
  z-index: 1020;
  border-bottom: 4px solid #3b4f64;

  .menu-icon {
    display: none;
  }
  h1 {
    font-size: 22px;
    font-weight: 700;
    text-transform: capitalize;
    color: #3b4f64;
    width: 100%;
  }
  span {
    font-size: 14px;
    font-weight: 400 !important;
    margin-right: 1em;
  }
  .menu-icon {
    display: none;
  }
  svg {
    cursor: pointer;
  }
  .header-right-side {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .svg-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 12px;
    border-radius: 8px;
    background-color: #3f51c3;
    cursor: pointer;
    margin-right: 0.75em;
  }
  @media only screen and (max-width: 768px) {
    background: #3f51c3;
    width: 100%;
    position: fixed;
    height: 60px;
    padding: 0 0.75em;
    margin: 0;
    width: 100%;
    border-bottom: unset;

    .menu-icon {
      display: block;
    }
    h1 {
      display: none;
    }
    .svg-wrp {
      background-color: #fff;
      padding: 10px 11px;
      path {
        fill: #3f51c3 !important;
      }
    }
    button {
      color: #3f51c3 !important;
      background-color: #fff !important;
      font-weight: 600;
      font-size: 13px;
    }
  }
`;
