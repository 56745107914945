import styled from "styled-components";

const Popup = ({ setAction, submit, color }) => {
  return (
    <Container color={color}>
      <div className="popup">
        <h2>Confirmation!</h2>
        <p>Êtes-vous sûr de vouloir confirmer cette réservation?</p>
        <div className="btn-row">
          <button
            className="popup-btn btn-1"
            onClick={() => {
              setAction(null);
            }}
          >
            Annuler
          </button>
          <button
            className="popup-btn  btn-2"
            onClick={() => {
              setAction(null);
              submit();
            }}
          >
            Confirmer
          </button>
        </div>
      </div>
    </Container>
  );
};

export default Popup;

const Container = styled.div`
  top: 0;
  left: 0;
  background-color: rgba(17, 18, 38, 0.6);
  width: 100vw;
  position: fixed;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 9999;

  .popup {
    z-index: 5000;
    max-width: 400px;
    background: #fff;
    border-radius: 10px;
    width: 100%;
    padding: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  h2 {
    font-size: 18px;
    color: #777;
  }
  p {
    font-size: 14px;
    color: #3b4f64;
    font-weight: 500;
    margin-top: 2em;
    margin-bottom: 0.5em;
    text-align: center;
  }
  .popup-btn {
    padding: 8px 20px;
    border-radius: 4px;
    font-weight: 600;
    white-space: nowrap;
    margin-top: 2em;
    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
    color: rgb(90, 97, 106);
    background: #e4ebf1;
    &:hover {
      filter: brightness(95%);
    }
  }
  .btn-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .btn-2 {
    background: #56e78e;
    color: #fff;
  }
`;
