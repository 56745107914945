import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Logo from "../assets/logo.png";

const Menu = ({ menuIsActive, setMenuIsActive }) => {
  return (
    <Container menuIsActive={menuIsActive}>
      <div className="logo-wrapper">
        <NavLink to="/" onClick={() => setMenuIsActive(!menuIsActive)}>
          <img src={Logo} alt="logo" className="logo" />
        </NavLink>
      </div>
      <div className="dash-links">
        <NavLink
          exact
          activeClassName="active-link"
          className="dash-link"
          to="/"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
          >
            <path d="M21 13v10h-6v-6h-6v6h-6v-10h-3l12-12 12 12h-3zm-1-5.907v-5.093h-3v2.093l3 3z" />
          </svg>
          <span>Dashboard</span>
        </NavLink>
        <NavLink
          exact
          activeClassName="active-link"
          className="dash-link"
          to="/reservations"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
          >
            <path d="M7 1h17v2h-17v-2zm0 7h17v-2h-17v2zm0 5h17v-2h-17v2zm0 5h17v-2h-17v2zm0 5h17v-2h-17v2zm-5-22c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm0 9c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2zm0 9c-1.104 0-2 .896-2 2s.896 2 2 2 2-.896 2-2-.896-2-2-2z" />
          </svg>
          <span>Demandes</span>
        </NavLink>
        <NavLink
          exact
          activeClassName="active-link"
          className="dash-link"
          to="/users"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
          >
            <path d="M17.997 18h-11.995l-.002-.623c0-1.259.1-1.986 1.588-2.33 1.684-.389 3.344-.736 2.545-2.209-2.366-4.363-.674-6.838 1.866-6.838 2.491 0 4.226 2.383 1.866 6.839-.775 1.464.826 1.812 2.545 2.209 1.49.344 1.589 1.072 1.589 2.333l-.002.619zm4.811-2.214c-1.29-.298-2.49-.559-1.909-1.657 1.769-3.342.469-5.129-1.4-5.129-1.265 0-2.248.817-2.248 2.324 0 3.903 2.268 1.77 2.246 6.676h4.501l.002-.463c0-.946-.074-1.493-1.192-1.751zm-22.806 2.214h4.501c-.021-4.906 2.246-2.772 2.246-6.676 0-1.507-.983-2.324-2.248-2.324-1.869 0-3.169 1.787-1.399 5.129.581 1.099-.619 1.359-1.909 1.657-1.119.258-1.193.805-1.193 1.751l.002.463z" />
          </svg>
          <span>Utilisateurs</span>
        </NavLink>
        <NavLink
          exact
          activeClassName="active-link"
          className="dash-link"
          to="/rooms"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M20 23h2v1h-20v-1h2v-23h16v23zm-1-22h-14v22h14l-10-1.954v-18.015l10-2.031zm-7 11h-2v1h2v-1z" />
          </svg>
          <span>Salles</span>
        </NavLink>

        <NavLink
          activeClassName="active-link"
          className="dash-link"
          to="/account"
          onClick={() => setMenuIsActive(!menuIsActive)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
          >
            <path d="M24 13.616v-3.232c-1.651-.587-2.694-.752-3.219-2.019v-.001c-.527-1.271.1-2.134.847-3.707l-2.285-2.285c-1.561.742-2.433 1.375-3.707.847h-.001c-1.269-.526-1.435-1.576-2.019-3.219h-3.232c-.582 1.635-.749 2.692-2.019 3.219h-.001c-1.271.528-2.132-.098-3.707-.847l-2.285 2.285c.745 1.568 1.375 2.434.847 3.707-.527 1.271-1.584 1.438-3.219 2.02v3.232c1.632.58 2.692.749 3.219 2.019.53 1.282-.114 2.166-.847 3.707l2.285 2.286c1.562-.743 2.434-1.375 3.707-.847h.001c1.27.526 1.436 1.579 2.019 3.219h3.232c.582-1.636.75-2.69 2.027-3.222h.001c1.262-.524 2.12.101 3.698.851l2.285-2.286c-.744-1.563-1.375-2.433-.848-3.706.527-1.271 1.588-1.44 3.221-2.021zm-12 2.384c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z" />
          </svg>
          <span>Paramètre</span>
        </NavLink>
      </div>
    </Container>
  );
};

export default Menu;

const Container = styled.div`
  padding: 2em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: #3b4f64;
  transition: all 0.3s;
  overflow: hidden;
  height: 100%;
  width: 240px;
  z-index: 9999;
  .logo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 0 1.7em 0;
    width: 100%;
    min-width: 90px;
  }
  .logo {
    width: 100%;
    max-width: 160px;
    height: 100%;
    cursor: pointer;
  }
  .dash-links {
    padding: 1em 0;
    width: 100%;
  }
  .dash-link {
    padding: 1.1em 1.5em;
    cursor: pointer;
    svg {
      margin-right: 0.75em;
      fill: #fff;
      transition: all 0.4s;
      min-width: 24px;
    }
    span {
      letter-spacing: 0.5px;
      font-size: 14px;
      font-weight: 500 !important;
      color: #fff;
      transition: all 0.4s;
      white-space: nowrap;
    }
    &:hover {
      span {
        color: #fff;
      }
      svg {
        fill: #fff;
      }
    }
  }
  .active-link {
    background: #5276ea;
    border-left: 3px solid #dde2ff;
    span {
      color: #dde2ff;
    }
    svg {
      fill: #dde2ff;
    }
  }

  @media only screen and (max-width: 768px) {
    width: ${(props) => (props.menuIsActive ? "240px" : "0")};
    min-height: ${(props) =>
      props.menuIsActive ? "calc(100vh - 60px)" : "100vh"};
    top: ${(props) => (props.menuIsActive ? "60px" : "0")};
  }
`;
