import React from "react";
import styled from "styled-components";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const DatePicker = ({ small, date, name, label, setFieldValue }) => {
  return (
    <Container small={small}>
      <label>{label}</label>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          format="MM/dd/yyyy"
          placeholder="mm/dd/yyyy"
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          value={date}
          onChange={(d) => setFieldValue(name, d)}
        />
      </MuiPickersUtilsProvider>
    </Container>
  );
};

export default DatePicker;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  label {
    font-size: 15px;
    font-weight: 600;
    color: #4d4d4d;
    margin-bottom: 0.35em;
    margin-top: 1.5em;
  }
  .MuiFormControl-root {
    margin-top: 0 !important;
  }
  .MuiInputBase-root {
    padding: 10px;
    font-size: 14px;
    border-radius: 7px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    min-width: ${(props) => (props.small ? "170px" : "260px")};
    margin-bottom: 0.35em;
    padding: 4px 0px 4px 10px;
    max-width: ${(props) => (props.small ? "170px" : "unset")};
  }
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none !important;
  }
  #date-picker-inline-helper-text {
    font-size: 12px !important;
    color: red;
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif !important;
    letter-spacing: 0 !important;
  }
`;
