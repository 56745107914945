import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import styled from "styled-components";
import Layout from "../layouts/DefaultLayout";

const Admins = () => {
  const [users, setUsers] = useState([]);
  console.log(users);

  const history = useHistory();
  const db = firebase.firestore();
  let isMounted = true;

  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = [
      "type_compte",
      "fname",
      "lname",
      "email",
      "phone",
      "raison_sociale",
      "confirmed",
    ];

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // ** Downloads CSV
  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const getUsers = async () => {
    const tempArray = [];
    await db
      .collection("users")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          const admin = doc.data();
          admin.id = doc.id;
          tempArray.push(admin);
        });
        if (isMounted) {
          setUsers(tempArray);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const deleteAdmin = async (id) => {
    await db
      .collection("users")
      .doc(id)
      .delete()
      .then(() => {
        let arr = users;
        arr = arr.filter((item) => item.id !== id);
        setUsers(arr);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    isMounted = true;
    getUsers();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Layout>
      <Container>
        <div className="btn-container">
          <button onClick={() => downloadCSV(users)}>Exporter en CSV</button>
        </div>
        <div className="table">
          <div className="grid">
            <h6>Type Client</h6>
            <h6>Prénom & nom</h6>
            <h6>Email</h6>
            <h6>N° de téléphone</h6>
            <h6>Raison sociale</h6>
            <h6>Status de compte</h6>
            <h6 />
            {users.map((item, index) => (
              <Fragment key={index}>
                <h5 className="name">{item?.type_client}</h5>
                <h5 className="name">{item.fname + " " + item.lname}</h5>
                <h5>{item.email}</h5>
                <h5>{item.phone}</h5>
                <h5>{item?.raison_sociale}</h5>
                <h5>{!item.confirmed ? "Non confirmé" : "Confirmé"}</h5>
                <h5 className="icons">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="#f84b60"
                    onClick={() => deleteAdmin(item.id)}
                  >
                    <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    className="view"
                    fill="#777"
                    onClick={() =>
                      history.push({
                        pathname: "/edit-user",
                        state: item.id,
                      })
                    }
                  >
                    <path d="M19.769 9.923l-12.642 12.639-7.127 1.438 1.438-7.128 12.641-12.64 5.69 5.691zm1.414-1.414l2.817-2.82-5.691-5.689-2.816 2.817 5.69 5.692z" />
                  </svg>
                </h5>
              </Fragment>
            ))}
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Admins;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 1em 2em 0em 2em;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;

  .btn-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .btn-container > button {
    padding: 7px 25px;
    background: #3b4f64;
    color: white;
    margin-bottom: 20px;
  }
  h5 {
    font-size: 14px;
    font-weight: 600;
    color: #202862;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
    position: relative;
  }
  h6 {
    font-size: 13px;
    font-weight: 600;
    color: #3b4f64;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  .name {
    text-transform: capitalize;
  }
  .table {
    overflow-x: auto;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr 1.5fr 1fr 1fr 0.25fr;
    grid-template-rows: auto;
    padding-bottom: 4em;
  }
  .btn {
    background: #f84b60;
    color: #fff;
    padding: 5px 7px;
    border-radius: 7px;
    text-align: center;
    width: 100px;
    font-size: 13px;
    justify-content: center;
    cursor: pointer;
  }
  .green {
    background: #56e78e;
  }
  .icons {
    justify-content: center;
    padding: 0;
    svg {
      cursor: pointer;
      margin: 10px;
    }
  }
`;
