import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCbub_peudJKYuKrC_49lnh7CMigpED3-Q",
  authDomain: "technopark-b1cdf.firebaseapp.com",
  projectId: "technopark-b1cdf",
  storageBucket: "technopark-b1cdf.appspot.com",
  messagingSenderId: "960029262547",
  appId: "1:960029262547:web:754e762e4c2f88e1cb4a26",
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

export default firebaseConfig;
