import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { Formik, Form } from "formik";
import { useLocation, useHistory } from "react-router-dom";
import * as Yup from "yup";
import firebase from "firebase/app";
import "firebase/firestore";
import CreatableSelect from "react-select/creatable";

import Layout from "../layouts/DefaultLayout";
import Button from "../components/elements/Button";
import CustomInput from "../components/elements/CustomInput";
import CustomSelect from "../components/elements/CustomSelect";
import Spinner from "../components/elements/Spinner";
import Add from "../assets/add.png";

export const colourOptions = [
  { value: "Format U", label: "Format U", color: "#00B8D9", isFixed: true },
];

const EditRoom = () => {
  const [loading, setLoading] = useState(0);
  const [uploading, setUploading] = useState(0);
  const [isSubmited, setIsSubmited] = useState(0);
  const [images, setImages] = useState([]);
  const [data, setData] = useState(null);
  const location = useLocation();
  const [inputValue, setInputValue] = useState([]);
  const [materials, setMaterials] = useState([]);

  useEffect(() => {
    if (data?.format?.length) {
      const newFormat = data?.format?.map((format) => {
        return { label: format, value: format };
      });
      setInputValue(newFormat);
    }
  }, [data]);

  const db = firebase.firestore();
  const history = useHistory();

  const update = async () => {
    await db
      .collection("rooms")
      .doc(location.state)
      .update({
        img: images[0],
        images,
        ...data,
        materials,
      })
      .then(() => {
        setLoading(false);
        setIsSubmited(0);
        alert("La salle a été modifier avec succès");
        history.push("/rooms");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRoom = async (id) => {
    await db
      .collection("rooms")
      .doc(id)
      .get()
      .then((doc) => {
        const item = doc.data();
        item.id = doc.id;
        setData(item);
        if (item?.materials?.length > 0) {
          setMaterials(item?.materials);
        }
        if (item.images) {
          setImages(item.images);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const uploadImage = async (files) => {
    const urls = images;
    setUploading(1);

    Promise.all(
      Object.entries(files).map(async ([key, value]) => {
        const storageRef = firebase
          .storage()
          .ref(`rooms/${new Date().getTime().toString()}`);
        await storageRef.put(value);
        const url = await storageRef.getDownloadURL();
        urls.push(url);
      })
    ).then(() => {
      setImages(urls);
      setUploading(0);
    });
  };

  const deleteImage = async (logo) => {
    const tempArray = images.filter((url) => url !== logo);
    setImages(tempArray);
  };

  useEffect(() => {
    if (!uploading && isSubmited) {
      update();
    }
  }, [uploading, isSubmited]);

  useEffect(() => {
    if (location?.state) {
      getRoom(location.state);
    }
  }, [location]);

  const addTool = (tool) => {
    const existing = materials.find(
      (material) => material.toLowerCase() === tool.toLowerCase()
    );
    if (existing) return;
    setMaterials((prev) => [...prev, tool]);
  };
  const deleteTool = (tool) => {
    const existing = materials.find(
      (material) => material.toLowerCase() === tool.toLowerCase()
    );
    if (!existing) return;
    const newTools = materials.filter(
      (material) => material.toLowerCase() !== tool.toLowerCase()
    );
    setMaterials(newTools);
  };

  return (
    <Layout>
      <Container>
        <Formik
          enableReinitialize
          initialValues={{
            name: data?.name || "",
            type: data?.type || "",
            capacite: data?.capacite || "",
            ville: data?.ville || "",
            dimensions: data?.dimensions || "",
            chaises: data?.chaises || "",
            tables: data?.tables || "",
            climatisation: data?.climatisation || "",
            tarif_ass_demi_jour: data?.tarif_ass_demi_jour || "",
            tarif_ass_jour: data?.tarif_ass_jour || "",
            tarif_entre_demi_jour: data?.tarif_entre_demi_jour || "",
            tarif_entre_jour: data?.tarif_entre_jour || "",
            tarif_two_heures: data?.tarif_two_heures || "",
            tarif_four_heures: data?.tarif_four_heures || "",
            tarif_eight_heures: data?.tarif_eight_heures || "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Obligatoire"),
          })}
          onSubmit={async (data) => {
            setLoading(true);
            setData(data);
            setIsSubmited(1);
          }}
        >
          {({ handleSubmit, values, errors }) => {
            console.log(errors);
            return (
              <Form className="form">
                <div className="row">
                  <CustomInput
                    label="Nom de salle"
                    name="name"
                    id="name"
                    type="text"
                    placeholder="Nom"
                  />

                  <div style={{ marginRight: 10 }}>
                    <CustomSelect
                      label="Type de salle"
                      name="type"
                      id="type"
                      type="text"
                      data={["Réunion", "Formation", "Conférence"]}
                    />
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <CustomSelect
                      label="Climatisation"
                      name="climatisation"
                      id="climatisation"
                      type="text"
                      data={["Oui", "Non"]}
                    />
                  </div>
                  <CustomInput
                    label="Ville"
                    name="ville"
                    id="ville"
                    type="text"
                  />
                  <CustomInput
                    label="Capacité"
                    name="capacite"
                    id="capacite"
                    type="text"
                  />
                  <CustomInput
                    label="Dimension"
                    name="dimensions"
                    id="dimensions"
                    type="text"
                  />
                  <div
                    className="reselect-container"
                    style={{ marginRight: 10 }}
                  >
                    <label>Format:</label>
                    <CreatableSelect
                      styles={{
                        container: (base) => ({
                          ...base,
                          width: "300px;",
                        }),
                        menu: (base) => ({
                          ...base,
                          width: "300px !important",
                        }),
                        menuList: (base) => ({
                          ...base,
                          width: "300px !important",
                        }),
                        option: (base) => ({
                          ...base,
                          width: "290px !important",
                        }),

                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          width: "300px !important",
                        }),
                      }}
                      isMulti
                      options={colourOptions}
                      className="reselect-input"
                      onChange={(newValue) => setInputValue(newValue)}
                      value={inputValue}
                    />
                  </div>
                  <CustomInput
                    label="Chaises"
                    name="chaises"
                    id="chaises"
                    type="text"
                  />
                  <CustomInput
                    label="Tables"
                    name="tables"
                    id="tables"
                    type="text"
                  />
                  {values.type === "Réunion" ? (
                    <>
                      <CustomInput
                        label="Tarif 2h"
                        name="tarif_two_heures"
                        id="tarif_two_heures"
                        type="text"
                      />
                      <CustomInput
                        label="Tarif 4h"
                        name="tarif_four_heures"
                        id="tarif_four_heures"
                        type="text"
                      />
                      <CustomInput
                        label="Tarif 8h"
                        name="tarif_eight_heures"
                        id="tarif_eight_heures"
                        type="text"
                      />
                    </>
                  ) : (
                    <>
                      <CustomInput
                        label="Tarif association 1/2 journée"
                        name="tarif_ass_demi_jour"
                        id="tarif_ass_demi_jour"
                        type="text"
                      />
                      <CustomInput
                        label="Tarif association 1 journée"
                        name="tarif_ass_jour"
                        id="tarif_ass_jour"
                        type="text"
                      />
                      <CustomInput
                        label="Tarif entreprise 1/2 journée"
                        name="tarif_entre_demi_jour"
                        id="tarif_entre_demi_jour"
                        type="text"
                      />
                      <CustomInput
                        label="Tarif entreprise 1 journée"
                        name="tarif_entre_jour"
                        id="tarif_entre_jour"
                        type="text"
                      />
                    </>
                  )}
                </div>
                <div className="row">
                  <CustomInput
                    label="Matériel technique
"
                    name="tool"
                    id="tool"
                    type="text"
                  />
                  <Button
                    title="Ajouter"
                    radius="7px"
                    padding="10.5px 26px"
                    type="button"
                    handleClick={() => addTool(values.tool)}
                  />
                </div>
                <div className="table">
                  <div className="grid">
                    <h6>#</h6>
                    <h6>Nom</h6>
                    <h6 />
                    {materials.map((item, index) => (
                      <Fragment key={index}>
                        <h5 className="name">{index + 1}</h5>
                        <h5>{item}</h5>
                        <h5 className="icons">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="#f84b60"
                            onClick={() => deleteTool(item)}
                          >
                            <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
                          </svg>
                        </h5>
                      </Fragment>
                    ))}
                  </div>
                </div>
                <h2 className="photos-label">Photos de salle</h2>
                <input
                  type="file"
                  id="file"
                  multiple
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={(e) => uploadImage(e.target.files)}
                />
                <label className="file-label" htmlFor="file">
                  {uploading ? (
                    <Spinner />
                  ) : (
                    <>
                      <img src={Add} alt="logo" width="105px" height="67px" />
                      <p>Ajouter une photo</p>
                    </>
                  )}
                </label>
                <div className="images">
                  {images.map((img, index) => (
                    <div className="img-wrp" key={index}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        className="delete"
                        onClick={() => deleteImage(img)}
                      >
                        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z" />
                      </svg>
                      <img src={img} alt="img" className="img" />
                    </div>
                  ))}
                </div>
                <div className="add-btn">
                  <Button
                    handleClick={handleSubmit}
                    title={loading ? "Modifier..." : "Modifier"}
                    type="submit"
                    radius="7px"
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </Layout>
  );
};

export default EditRoom;

const Container = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 2em 2em 2em 2em;
  box-shadow: rgb(237 239 247 / 47%) 6px 6px 6px,
    rgb(237 239 247 / 47%) 0px 0px 0px;
  .file-label {
    width: 100%;
    border-radius: 12px;
    border: 1px dashed #000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1em !important;
    box-shadow: 0px 4px 10px rgba(193, 193, 193, 0.3);
    min-height: 400px;
    max-width: 1200px;
    margin-top: 0.5em !important;
    p {
      margin-top: 1.5em;
      max-width: 200px;
      text-align: center;
      color: #999;
      line-height: 1.6em;
      font-size: 14px;
      font-weight: 600;
    }
  }
  #file {
    display: none;
  }
  .img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: cover;
  }
  .img-wrp {
    position: relative;
    width: 200px;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 10px rgba(193, 193, 193, 0.3);
    margin: 1em 1em 0em 0em;
    border-radius: 12px;
    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
  .images {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
  }
  .delete {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    opacity: 0;
    transition: all 0.5s;
    fill: #ff7b7b;
    cursor: pointer;
    border-radius: 100px;
    background: #fff;
  }
  .row {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    flex-wrap: wrap;
    div {
      width: fit-content;
    }
  }
  label {
    margin-top: 1.5em;
    color: #202862 !important;
    font-size: 14px;
    font-weight: 600;
    color: #4d4d4d;
    margin-bottom: 0.35em;
  }
  input {
    margin-right: 1em;
  }
  .photos-label {
    margin-top: 1.5em;
    color: #202862 !important;
    font-size: 14px;
    font-weight: 600;
    color: #4d4d4d;
    margin-bottom: 0.35em;
  }
  .add-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .table {
    overflow-x: auto;
    margin-top: 3em;
    max-width: 600px;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 60px;
    grid-template-rows: auto;
    padding-bottom: 4em;
  }
  h6 {
    font-size: 13px;
    font-weight: 600;
    color: #3b4f64;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1em 0.5em 1em 0;
    border-bottom: 1px solid #dfe0eb;
  }
  h5 {
    font-size: 14px;
    font-weight: 700;
    color: #202862;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 1.25em 0.5em 1.25em 0;
    border-bottom: 1px solid #dfe0eb;
    position: relative;
    text-transform: capitalize;
  }
`;
